@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AktivBlack";
  src: url("../src/fonts/AktivGrotesk_Trial_Blk.ttf");
}

@font-face {
  font-family: "AktivXBold";
  src: url("../src/fonts/AktivGrotesk_Trial_XBd.ttf");
}
